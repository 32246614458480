import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { paths } from 'src/routes/paths';
import { Button } from '@mui/material';
import {
  createRegistration,
  updateRegistration,
} from 'src/api/services/registration-list.services';
// import { useAuthContext } from 'src/auth/hooks';
import FormProvider, { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';
import { RHFMultiCustomCheckbox } from 'src/components/hook-form/rhf-custom-checkbox';
import RHFRadioCustomGroup from 'src/components/hook-form/rhf-custom-radio-group';
import { useLocales } from 'src/locales';
import {
  currentUser,
  detail_of_various_service_work_options,
  dropDownLabelValue,
  dropDownLabelValue_,
  getErrorMg,
  institution_types_options,
  jilla_places_options,
  scope_of_the_organization_options,
} from 'src/utils/utils';
import * as Yup from 'yup';
import { getJillaData, getTalukaData } from 'src/api/services/location.services';
import { createFamily, updateFamily } from 'src/api/services/family-list.services';
import { userData } from '../profile/userData';

const RegisterSchema = Yup.object().shape({
  name: Yup.string().required('Organization name required'),
  address: Yup.string().required('This field is required!'),
  jilla: Yup.object().required('This field is required!'),
  taluka: Yup.object().required('This field is required!'),
  phoneNumber: Yup.string()
    .matches(/^(|[6-9][0-9]{9})$/, 'Please enter a valid Mobile Number.')
    .typeError('Please enter a valid Mobile Number.'),
  pincode: Yup.string()
    .matches(/^(?!000000)[1-9][0-9]{5}$/, 'Please enter a valid 6-digit Pincode.')
    .required('This field is required!'),
  familyMember: Yup.string().required('This field is required!'),
  highestAge: Yup.string().required('This field is required!'),
  lowestAge: Yup.string().required('This field is required!'),
});

const defaultValues = {
  name: '',
  address: '',
  jilla: null,
  taluka: null,
  phoneNumber: '',
  pincode: '',
  familyMember: '',
  highestAge: '',
  lowestAge: '',
};

export default function NewFamilyRegistrationForm() {
  const [jillaOptions, setJillaOptions] = useState([]);
  const [talukaOptions, setTalukaOptions] = useState([]);
  const location = useLocation();
  const state = location.state || {};
  const { familyData } = state;
  const navigate = useNavigate();

  // const { newRegister, authenticated } = useAuthContext();

  const locales = useLocales();
  const { t } = useLocales();

  useEffect(() => {
    if (locales.currentLang.value === 'en') {
      locales.onChangeLang('guj');
    }
  }, []);

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { isSubmitting, errors },
  } = methods;
  const values = watch();

  useEffect(() => {
    if (familyData) {
      const data = {
        taluka: { label: familyData?.taluka?.name || '', value: familyData?.taluka?.id || '' },
        jilla: { label: familyData?.district?.name || '', value: familyData?.district?.id || '' },
      };
      reset({ ...familyData, ...data });
    }
  }, [familyData]);

  useEffect(() => {
    // getVibhagData()
    //   .then((res) => setVibhagOptions(dropDownLabelValue(res.data)))
    //   .catch((err) => console.error('error', err));
    getJillaData()
      .then((res) => setJillaOptions(dropDownLabelValue_(res.data)))
      .catch((err) => console.error('error', err));
    getTalukaData()
      .then((res) => setTalukaOptions(dropDownLabelValue_(res.data)))
      .catch((err) => console.error('error', err));
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    const formattedData = {
      name: data.name,
      address: data.address,
      pincode: data.pincode,
      districtId: data?.jilla?.value,
      talukaId: data?.taluka?.value,
      phoneNumber: data.phoneNumber,
      familyMember: parseInt(data.familyMember, 10),
      highestAge: parseInt(data.highestAge, 10),
      lowestAge: parseInt(data.lowestAge, 10),
      userId: currentUser()?.id,
    };

    try {
      if (familyData) {
        // Update existing registration
        await updateFamily(familyData.id, formattedData);
        enqueueSnackbar('Registration Updated Successfully', { variant: 'success' });
        navigate(paths.hssfPaths.familyRegistration.list);
      } else {
        // Create new registration
        await createFamily(formattedData);
        enqueueSnackbar('Registration Created Successfully', { variant: 'success' });
      }
      reset();
    } catch (error) {
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
    }
  });

  return (
    <FormProvider methods={methods} onSubmit={onSubmit} sx={{ pb: '80px' }}>
      <Grid container spacing={2.5}>
        <Grid xs={12}>
          <RHFTextField name="name" label={`${t('family_Registration.name')} *`} />
        </Grid>

        <Grid xs={12}>
          <RHFTextField name="address" label={`${t('family_Registration.address')} *`} />
        </Grid>

        <Grid xs={12} md={6}>
          <RHFAutocomplete
            name="jilla"
            label={`${t('family_Registration.district')} *`}
            options={jillaOptions}
            getOptionLabel={(option) =>
              locales.currentLang.value === 'guj' ? option.label : option.label
            }
            isOptionEqualToValue={(option, value) => option.value === value.value}
            onChange={(e, val) => {
              setValue('jilla', val);
              clearErrors('jilla');
              if (val) {
                getTalukaData({ districtId: val.value })
                  .then((res) => {
                    const talukaData = dropDownLabelValue_(res.data);
                    setTalukaOptions(talukaData);
                    // Reset jilla and taluka fields when vibhag changes
                    setValue('taluka', null);
                  })
                  .catch((err) => console.error('Error fetching jilla data:', err));
              }
            }}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <RHFAutocomplete
            name="taluka"
            label={`${t('family_Registration.taluka')} *`}
            options={talukaOptions}
            // getOptionLabel={(option) =>
            //   locales.currentLang.value === 'guj' ? option.label : option.label
            // }
            // isOptionEqualToValue={(option, value) => option.value === value.value}
          />
        </Grid>

        <Grid xs={12} md={6}>
          <RHFTextField
            type="number"
            name="phoneNumber"
            label={`${t('family_Registration.phone_number')} *`}
          />
        </Grid>

        <Grid xs={12} md={6}>
          <RHFTextField
            type="number"
            name="pincode"
            label={`${t('family_Registration.pin_code')} *`}
          />
        </Grid>

        <Grid xs={12} md={6}>
          <RHFTextField
            name="familyMember"
            label={`${t('family_Registration.family_members')} *`}
          />
        </Grid>

        <Grid xs={12} md={6}>
          <RHFTextField
            type="number"
            name="highestAge"
            label={`${t('family_Registration.highest_age')} *`}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <RHFTextField
            type="number"
            name="lowestAge"
            label={`${t('family_Registration.lowest_age')} *`}
          />
        </Grid>

        <Grid xs={12} textAlign="end">
          <LoadingButton
            sx={{ maxWidth: 'fit-content', mr: 2 }}
            color="inherit"
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {familyData ? t('update') : t('register')}
          </LoadingButton>

          {familyData && (
            <Button
              sx={{ maxWidth: 'fit-content' }}
              color="inherit"
              size="large"
              variant="outlined"
              onClick={() => {
                // Reset form and clear registrationData
                methods.reset();
                navigate(paths.hssfPaths.familyRegistration.list);
              }}
            >
              {t('cancel')}
            </Button>
          )}
        </Grid>
      </Grid>
    </FormProvider>
  );
}
