import React from 'react';
import { Helmet } from 'react-helmet-async';

const EducationalMain = () => (
  <>
    <Helmet>
      <title> HSSF : EDUCATIONAL</title>
    </Helmet>

    <h1>Educational Main</h1>
  </>
);

export default EducationalMain;
