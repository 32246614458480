import React, { useEffect } from 'react';

const QrCode = () => {
  useEffect(() => {
    const debug = document.getElementById('debug');
    const webBaseUrl = 'https://hssfgujarat.co.in';
    const PACKAGE_NAME = 'com.aveosoftware.hssf';

    function log(msg) {
      debug.innerHTML += `${new Date().toLocaleTimeString()}: ${msg}<br>`;
    }

    function getPathFromUrl() {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('path') || '';
    }

    function openApp(path) {
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      log(`Platform detected: ${isIOS ? 'iOS' : 'Android'}`);
      const appScheme = `hssf://app/${path}`;
      const webFallbackUrl = `${webBaseUrl}/${path}`;
      if (isIOS) {
        log('Attempting to open iOS app...');
        window.location.href = appScheme;
        setTimeout(() => {
          if (!document.hidden) {
            log('App not installed, redirecting to web page...');
            window.location.href = webFallbackUrl;
          }
        }, 2500);
      } else {
        log(`Attempting to open Android app with path: ${path}`);
        try {
          const intentUrl =
            `intent://app/${path}#Intent;` +
            'scheme=hssf;' +
            `package=${PACKAGE_NAME};` +
            `S.browser_fallback_url=${encodeURIComponent(webFallbackUrl)};end`;
          window.location.href = intentUrl;
        } catch (error) {
          log('Error opening app, redirecting to web...');
          window.location.href = webFallbackUrl;
        }
      }
    }

    const path = getPathFromUrl();
    log(`Detected path: ${path}`);
    if (path) {
      openApp(path);
    } else {
      log('No path provided');
      window.location.href = webBaseUrl;
    }

    document.addEventListener('visibilitychange', () => {
      log(document.hidden ? 'App opened successfully!' : 'Returned to browser');
    });

    log(`UserAgent: ${navigator.userAgent}`);
  }, []);

  return (
    <div className="container">
      <h1>Redirecting...</h1>
      <div
        id="debug"
        style={{
          marginTop: '20px',
          padding: '10px',
          textAlign: 'left',
          fontFamily: 'monospace',
          fontSize: '12px',
          background: '#fff',
          borderRadius: '8px',
        }}
      />
    </div>
  );
};

export default QrCode;
