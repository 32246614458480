import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { RHFTextField } from 'src/components/hook-form';

export default function PlayerInputs({
  players,
  addPlayer,
  removePlayer,
  maxPlayers,
  gender,
  gameIndex,
  schoolType,
}) {
  const genderCapitalized = gender.charAt(0).toUpperCase() + gender.slice(1);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">{`${genderCapitalized} Players`}</Typography>
      </Grid>
      {players.map((player, index) => (
        <Grid item xs={12} key={index}>
          <Typography variant="subtitle1">{`${genderCapitalized} Player ${index + 1}`}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <RHFTextField
                name={`${schoolType}.games[${gameIndex}].${gender}Players[${index}].name`}
                label="Name"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <RHFTextField
                name={`${schoolType}.games[${gameIndex}].${gender}Players[${index}].std`}
                label="Std"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <RHFTextField
                name={`${schoolType}.games[${gameIndex}].${gender}Players[${index}].grNo`}
                label="GR No"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} textAlign="end" mt={2}>
            <Button variant="outlined" onClick={() => removePlayer(index)}>
              Remove Player
            </Button>
          </Grid>
        </Grid>
      ))}
      {players.length < maxPlayers && (
        <Grid item xs={12} textAlign="end">
          <Button onClick={addPlayer} variant="outlined">
            {` + Add ${genderCapitalized} Player`}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
