import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { SplashScreen } from 'src/components/loading-screen';
import AuthClassicLayout from 'src/layouts/auth/classic';
import AddRegistrationMain from 'src/pages/HSSF/registration/add-registration';
import { paths } from '../paths';

const RegistrationMain = lazy(() => import('src/pages/HSSF/registration/registration'));

export const hssfRegistrationRoutes = [
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: paths.hssfPaths.registration.root,
        children: [
          {
            element: (
              <AuthClassicLayout>
                <RegistrationMain />
              </AuthClassicLayout>
            ),

            index: true,
          },
        ],
      },
      {
        path: paths.hssfPaths.registration.add,
        element: <AddRegistrationMain />,
      },
    ],
  },
];
