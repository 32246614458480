import React from 'react';
import { Grid, Typography } from '@mui/material';
import { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';
import { locales } from 'numeral';
import { game_district_options } from 'src/utils/utils';

export default function PrimarySchoolForm() {
  return (
    <>
      <Grid item xs={12} my={2}>
        <Typography variant="h4" fontWeight={800}>
          Primary School
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <RHFTextField name="primarySchool.name" label="Primary School Name" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            name="primarySchool.udise"
            label="Primary School UDISE number"
            inputProps={{ maxLength: 12 }}
          />
        </Grid>
        <Grid item xs={12}>
          <RHFTextField
            name="primarySchool.address"
            label="Primary School Address"
            multiline
            rows={3}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField type="number" name="primarySchool.pincode" label="Primary School Pincode" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFAutocomplete
            name="primarySchool.district"
            label="Primary School Jilla"
            options={game_district_options}
            // getOptionLabel={(option) =>
            //   (locales.currentLang?.value || 'en') === 'guj' ? option.guj_label : option.label
            // }
            isOptionEqualToValue={(option, value) => option.value === value.value}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            type="number"
            name="primarySchool.phone"
            label="Primary School Phone number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            name="primarySchool.email"
            label="Primary School Email Address"
            type="email"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField name="primarySchool.principal" label="Primary School Principal Name" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            type="number"
            name="primarySchool.principalPhone"
            label="Primary School Principal Phone"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            name="primarySchool.principalEmail"
            label="Primary School Principal Email Address"
            type="email"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            name="primarySchool.nodelTeacher"
            label="Primary School Nodel Teacher Name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            type="number"
            name="primarySchool.nodelTeacherPhone"
            label="Primary School Nodel Teacher Number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            name="primarySchool.paycenter"
            label="Primary school paycenter name/number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField name="primarySchool.crc" label="Primary School CRC name/number" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField name="primarySchool.brc" label="Primary School BRC name/number" />
        </Grid>
      </Grid>
    </>
  );
}
