import React from 'react';
import { Grid, Typography } from '@mui/material';
import { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';
import { game_district_options } from 'src/utils/utils';
import { locales } from 'numeral';

export default function SecondarySchoolForm() {
  return (
    <>
      <Grid item xs={12} my={2}>
        <Typography variant="h4" fontWeight={800}>
          Secondary School
        </Typography>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <RHFTextField name="secondarySchool.name" label="Secondary School Name" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            name="secondarySchool.ssindex"
            label="Secondary School SSINDEX number"
            inputProps={{ placeholder: 'e.g., 22.4444' }}
          />
        </Grid>
        <Grid item xs={12}>
          <RHFTextField
            name="secondarySchool.address"
            label="Secondary School Address"
            multiline
            rows={2}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            type="number"
            name="secondarySchool.pincode"
            label="Secondary School Pincode"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFAutocomplete
            name="secondarySchool.district"
            label="Secondary School District"
            options={game_district_options}
            // getOptionLabel={(option) =>
            //   (locales.currentLang?.value || 'en') === 'guj' ? option.guj_label : option.label
            // }
            isOptionEqualToValue={(option, value) => option.value === value.value}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            type="number"
            name="secondarySchool.phone"
            label="Secondary School Phone number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField name="secondarySchool.principal" label="Secondary School Principal Name" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            type="number"
            name="secondarySchool.principalPhone"
            label="Secondary School Principal Phone"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            name="secondarySchool.principalEmail"
            label="Secondary School Principal Email Address"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            name="secondarySchool.nodelTeacher"
            label="Secondary School Nodel Teacher Name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            type="number"
            name="secondarySchool.nodelTeacherPhone"
            label="Secondary School Nodel Teacher Number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField name="secondarySchool.email" label="Secondary School Email Address" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField name="secondarySchool.qdc" label="Secondary school QDC name/number" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField name="secondarySchool.svsc" label="Secondary School SVS name/number" />
        </Grid>
      </Grid>
    </>
  );
}
