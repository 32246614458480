import React from 'react';
import { Helmet } from 'react-helmet-async';
import FamilyRegistrationView from 'src/sections/HSSF/family/family-registration-view';

const FamilyRegistrationMain = () => (
  <>
    <Helmet>
      <title> HSSF : FAMILY REGISTRATION</title>
    </Helmet>

    <FamilyRegistrationView />
  </>
);

export default FamilyRegistrationMain;
