import React from 'react';
import { Helmet } from 'react-helmet-async';
import AddFamilyRegistrationView from 'src/sections/HSSF/family/add-family-registration';

const AddFamilyRegistrationMain = () => (
  <>
    <Helmet>
      <title> HSSF : ADD FAMILY REGISTRATION</title>
    </Helmet>

    <AddFamilyRegistrationView />
  </>
);

export default AddFamilyRegistrationMain;
