import React from 'react';
import { Helmet } from 'react-helmet-async';
import AddGameRegistrationView from 'src/sections/HSSF/gameRegistration/add-game-registration';

const AddGameRegistrationMain = () => (
  <>
    <Helmet>
      <title> HSSF : ADD GAME REGISTRATION</title>
    </Helmet>

    <AddGameRegistrationView />
  </>
);

export default AddGameRegistrationMain;
