import Container from '@mui/material/Container';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { useLocales } from 'src/locales';
import { ROLES } from 'src/utils/utils';
import { paths } from 'src/routes/paths';
import NewGameRegistrationForm from './new-game-registration-form';

export default function AddFamilyRegistrationView() {
  const { t } = useLocales();
  const settings = useSettingsContext();
  const user = JSON.parse(localStorage.getItem('user'));

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={t('game_registration.title')}
        links={[
          {
            name: t('dashboard'),
            href:
              user?.user_role === ROLES.superAdmin
                ? paths.hssfPaths.root
                : paths.hssfPaths.registration.list,
          },
          {
            name: t('game_registration.title'),
            href: paths.hssfPaths.gameRegistration.list,
          },
        ]}
        sx={{ mb: { xs: 3, md: 5 }, my: 2.5 }}
      />
      <NewGameRegistrationForm />
    </Container>
  );
}
