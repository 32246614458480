// src/sections/HSSF/profile/profile-utils.js
export const userData = {
  id: 1,
  firstName: 'Tarak',
  lastName: 'Mehta',
  email: 'tarak@gmail.com',
  username: 'tarak@gmail.com',
  provider: 'local',
  confirmed: false,
  blocked: false,
  mobileNo: '9562156222',
  whatsappNo: '9562156222',
  profession: 'doctor',
  address: 'A/12, surat',
  pinCode: '395010',
  anyOtherResponsibility: 'anything',
  typesOfActivities: 'Socialist (NGOs)',
  interest: ['Preserve Ecology', 'Sustain Environment'],
  workingPreference: 'daily',
  workingHrs: '1-3',
  responsibleForFair: false,
  password: 'User@123',
  vibhagId: 15,
  districtId: 34,
  talukaId: 242,
  districtName: 'Vadodara',
  talukaName: 'Vadodara',
  vibhagName: 'Vadodara',
  roleId: 5,
  otherTypesOfActivities: '',
  referenceRegistrationPersonName: null,
  referenceRegistrationPersonContact: null,
  otherTypesOfActivitiesChecked: false,
  userImage:
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQK_mAcrV3vVhLq6HK4c1liqGV59qhOwXdEGw&s',
  aadharNumber: '123456789012',
  aadharImage:
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQK_mAcrV3vVhLq6HK4c1liqGV59qhOwXdEGw&s',
  createdAt: '2024-09-21T12:54:09.175Z',
  updatedAt: '2024-09-22T13:01:51.652Z',
  teamMembers: [
    {
      id: 2,
      userId: 4,
      activityId: null,
      teamId: 2,
      createdAt: '2024-09-22T12:07:55.680Z',
      updatedAt: '2024-09-22T12:07:55.680Z',
      activity: null,
    },
    {
      id: 3,
      userId: 4,
      activityId: 7,
      teamId: 3,
      createdAt: '2024-09-22T12:10:23.150Z',
      updatedAt: '2024-09-22T13:01:51.960Z',
      activity: {
        id: 7,
        name: 'Socialist (NGOs)',
      },
    },
  ],
};
