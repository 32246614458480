import { format } from 'date-fns';
import { enqueueSnackbar } from 'notistack';
import { paths } from 'src/routes/paths';

export const getErrorMg = (error) => {
  enqueueSnackbar(error?.error?.message || 'Invalid', { variant: 'error' });
};

export function convertInterestsToLabels({ string, options }) {
  const interests = string?.split(',');

  const labels = interests.map((interest) => {
    const match = options.find((option) => option.value === interest);
    return match ? match.label : interest;
  });

  return labels.join(', ');
}

export const dropDownLabelValue = (data) => {
  const returnData = data
    ? data.map((item) => ({
        value: item.id,
        label: item.attributes.name,
      }))
    : [];
  return returnData;
};
export const dropDownLabelValue_ = (data) => {
  const returnData = data
    ? data.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    : [];
  return returnData;
};

export const ROLES = {
  superAdmin: 'super-admin',
  prant: 'prant-admin',
  vibhag: 'vibhag-admin',
  jilla: 'district-admin',
  taluka: 'taluka-admin',
  karykarta: 'karykarta-user',
  admin: 'admin',
  user: 'user',
};

export const ROLES_ID = {
  superAdmin: 1,
  prant: 2,
  vibhag: 3,
  jilla: 4,
  taluka: 5,
  karykarta: 6,
  user: 7,
};

export const UserDetails = [
  {
    email: 'superadmin@gmail.com',
    user_name: 'Super Admin ',
    password: 'User@123',
    value: 'prant',
    role: 'super-admin',
    aayam: 'super-admin',
    user_location: {
      prant: 'gujarat',
      vibhag: null,
      jilla: null,
      taluka: null,
    },
  },
  {
    email: 'sachivprant@gmail.com',
    user_name: 'Sachiv - State',
    password: 'User@123',
    value: 'prant',
    role: 'prant-admin',
    aayam: 'sachiv',
    user_location: {
      prant: 'gujarat',
      vibhag: null,
      jilla: null,
      taluka: null,
    },
  },
  {
    email: 'karyalayaprant@gmail.com',
    user_name: 'Office - State',
    password: 'User@123',
    value: 'prant',
    role: 'prant-admin',
    aayam: 'karyalaya',
    user_location: {
      prant: 'gujarat',
      vibhag: null,
      jilla: null,
      taluka: null,
    },
  },
  {
    email: 'sayojakvibhag@gmail.com',
    user_name: 'Sayojak - Vibhag',
    password: 'User@123',
    value: 'vibhag',
    role: 'vibhag-admin',
    aayam: 'sayojak',
    user_location: {
      prant: 'gujarat',
      vibhag: 'navsari',
      jilla: null,
      taluka: null,
    },
  },
  {
    email: 'pracharjaheratvibhag@gmail.com',
    user_name: 'Prachar Jaherat - Vibhag',
    password: 'User@123',
    value: 'vibhag',
    role: 'vibhag-admin',
    aayam: 'prachar_jaherat',
    user_location: {
      prant: 'gujarat',
      vibhag: 'navsari',
      jilla: null,
      taluka: null,
    },
  },
  {
    email: 'sayojakjilla@gmail.com',
    user_name: 'Sayojak - District',
    password: 'User@123',
    value: 'jilla',
    role: 'jilla-user',
    aayam: 'sayojak',
    user_location: {
      prant: 'gujarat',
      vibhag: 'navsari',
      jilla: 'vadodra',
      taluka: null,
    },
  },
  {
    email: 'corporatesamparkjilla@gmail.com',
    user_name: 'Corporate Houses - District',
    password: 'User@123',
    value: 'jilla',
    role: 'jilla-user',
    aayam: 'corporate_sampark',
    user_location: {
      prant: 'gujarat',
      vibhag: 'navsari',
      jilla: 'vadodra',
      taluka: null,
    },
  },
  {
    email: 'itjilla@gmail.com',
    user_name: 'IT - District',
    password: 'User@123',
    value: 'jilla',
    role: 'jilla-user',
    aayam: 'it',
    user_location: {
      prant: 'gujarat',
      vibhag: 'navsari',
      jilla: 'vadodra',
      taluka: null,
    },
  },
  {
    email: 'sayojaktaluka@gmail.com',
    user_name: 'Sayojak - Taluka',
    password: 'User@123',
    value: 'taluka',
    role: 'taluka-user',
    aayam: 'sayojak',
    user_location: {
      prant: 'gujarat',
      vibhag: 'navsari',
      jilla: 'vadodra',
      taluka: 'karnavati_east',
    },
  },
  {
    email: 'parivarkshetrataluka@gmail.com',
    user_name: 'Family Organizations - Taluka',
    password: 'User@123',
    value: 'taluka',
    role: 'taluka-user',
    aayam: 'parivar_kshetra',
    user_location: {
      prant: 'gujarat',
      vibhag: 'navsari',
      jilla: 'vadodra',
      taluka: 'karnavati_east',
    },
  },
];
// who can access all module according roles
export const SuperAayamRoles = {
  sachiv: 'sachiv',
  sahsachiv: 'sahsachiv',
  sayojak: 'sayojak',
  sahsyojak: 'sahsyojak',
  super_admin: 'super-admin',
  karyalaya: 'karyalaya',
  it: 'it',
};

// who can generate report according roles
export const generateReportAayamRoles = {
  karyalaya: 'karyalaya',
  it: 'it',
};

export const currentUser = () => JSON.parse(localStorage.getItem('user'));

//  ! get-homeRoute for logged-In-User START
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === ROLES.prant) return paths.hssfPaths.prant.root; // State
  if (userRole === ROLES.vibhag) return paths.hssfPaths.vibhag.root; // vibhag
  if (userRole === ROLES.jilla) return paths.hssfPaths.jilla.root; // District
  if (userRole === ROLES.taluka) return paths.hssfPaths.taluka.root; // taluka
  return paths.auth.jwt.login;
};

// fair get year start
export const getFairYear = () => {
  const currentYear = new Date().getFullYear();
  // const viewYear = currentYear - (currentYear % 5);
  const viewYear = Math.ceil(currentYear / 5) * 5;
  // console.log('current fair year :', viewYear);
  return viewYear;
};

// ! get-data for API call
export const useCurrentDashboardData = () => {
  // get - local - data
  const getData = JSON.parse(localStorage.getItem('hssf_dashboard'));

  // set - local - data
  const setData = (value) => {
    const setLocalData = { ...getData, ...value };
    localStorage.setItem('hssf_dashboard', JSON.stringify(setLocalData));
  };

  return {
    setData,
    getData,
  };
};

// Interest : -----
export const interests_options = [
  {
    value: 'conserve_forests_and_protect_wildlife',
    label: 'Conserve Forests & Protect WildLife',
    guj_label: 'વન અને વન્યજીવન સંરક્ષણ',
  },
  { value: 'preserve_ecology', label: 'Preserve Ecology', guj_label: 'જીવસૃષ્ટિ સંતુલન' },
  { value: 'sustain_environment', label: 'Sustain Environment', guj_label: 'પર્યાવરણ સંરક્ષણ' },
  {
    value: 'inculcate_family_and_human_Values',
    label: 'Inculcate Family & Human Values',
    guj_label: 'પારિવારિક તથા માનવિય મુલ્યો નુ જતન',
  },
  {
    value: 'foster_womens_honour',
    label: 'Foster Women`s Honour',
    guj_label: 'નારી સન્માન અને સંરક્ષણ',
  },
  { value: 'instill_patriotism', label: 'Instill Patriotism', guj_label: 'રાષ્ટ્રભક્તિ જાગરણ' },
];

// Section Types : -----
export const aayam_types_options = [
  { value: 'dharmik', label: 'Dharmik Institutions', guj_label: 'ધાર્મિક (મઠ મંદિર)' },
  {
    value: 'shikshan_sanstha_sampark',
    label: 'Educational Institutions',
    guj_label: 'શૈક્ષણિક સંસ્થા સંપર્ક',
  },
  {
    value: 'samajik_sanstha_sampark',
    label: 'Socialist (NGOs)',
    guj_label: 'સામાજિક સંસ્થા (NGO/ સેવા) સંપર્ક',
  },
  { value: 'yuva_shakti', label: 'Youth Wings', guj_label: 'યુવા શક્તિ' },
  { value: 'nari_shakti', label: 'Female Wings', guj_label: 'નારી શક્તિ' },
  { value: 'parivar_kshetra', label: 'Family Organizations', guj_label: 'પરિવાર ક્ષેત્ર' },
  { value: 'corporate_sampark', label: 'Corporate Houses', guj_label: 'કોર્પોરેટ સંપર્ક' },
  { value: 'prachar_jaherat', label: 'Media / Social Media', guj_label: 'પ્રચાર - પ્રસાર' },
  { value: 'karyalaya', label: 'Office', guj_label: 'કાર્યાલય' },
  { value: 'it', label: 'IT', guj_label: 'IT' },
  { value: 'none', label: 'None', guj_label: 'એકે નહિ' },
];

// Person`s Type : -----
export const person_types_option = [
  { value: 'individual', label: 'Individual', guj_label: 'વ્યક્તિગત' },
  { value: 'organization', label: 'Organization', guj_label: 'સંસ્થા' },
];

// working-time : -----
export const working_times_option = [
  { value: 'daily', label: 'Daily', guj_label: 'દૈનિક' },
  { value: 'weekly', label: 'Weekly', guj_label: 'સાપ્તાહિક' },
  { value: 'monthly', label: 'Monthly', guj_label: 'માસિક' },
  { value: 'none', label: 'None', guj_label: 'એકે નહિ' },
];

// working-hours : -----
export const working_hours_option = [
  { value: '1-3 hrs', label: '1-3 hrs', guj_label: '1-3 કલાક' },
  { value: '3-5 hrs', label: '3-5 hrs', guj_label: '3-5 કલાક' },
  { value: 'More than 5 hrs', label: 'More than 5 hrs', guj_label: '5 કલાકથી વધુ' },
  { value: 'none', label: 'None', guj_label: 'એકે નહિ' },
];

// Section Types : -----
export const organization_types_options = [
  { value: 'dharmik', label: 'Dharmik Institutions', guj_label: 'ધાર્મિક (મઠ મંદિર)' },
  { value: 'ngo', label: 'NGO', guj_label: 'NGO' },
  { value: 'corporate', label: 'Corporate', guj_label: 'કોર્પોરેટ' },
  { value: 'shaikshanik', label: 'Educational Institutions', guj_label: 'શૈક્ષણિક સંસ્થા સંપર્ક' },
  {
    value: 'parivar_institution',
    label: 'Parivar Institution',
    guj_label: 'પરિવાર સંસ્થા',
  },
  {
    value: 'jatigat_institution',
    label: 'Jatigat Institution',
    guj_label: 'જાતિગત સંસ્થા',
  },
];

export const customCombineKeyFunction = (arrayData, combineKeys, gujCombineKeys) =>
  arrayData?.map((item) => {
    const combinedData = combineKeys
      ? combineKeys
          .map((key) => item[key])
          .filter((val) => val !== null && val !== undefined && val !== '')
          .join(' / ')
      : '';
    const gujCombineData = gujCombineKeys
      ? gujCombineKeys
          .map((key) => item[key])
          .filter((val) => val !== null && val !== undefined && val !== '')
          .join(' / ')
      : '';

    return { ...item, combine_data_key: combinedData, guj_combine_data_key: gujCombineData };
  });

// date range view for dd/mm/yyyy
export const dateRangeView = (startDate, endDate) =>
  startDate &&
  (format(startDate, 'dd/MM/yyyy') === (endDate && format(endDate, 'dd/MM/yyyy'))
    ? format(startDate, 'dd/MM/yyyy')
    : `${format(startDate, 'dd/MM/yyyy')}${endDate ? '  -  ' : ''}${
        endDate ? format(endDate, 'dd/MM/yyyy') : ''
      }`);

// institution Types : -----
export const institution_types_options = [
  { value: 'dharmik', label: 'Dharmik Institutions', guj_label: 'ધાર્મિક (મઠ મંદિર)' },
  {
    value: 'based_on_caste',
    label: 'Based On Caste',
    guj_label: 'જાતિ આધારિત',
  },
  {
    value: 'socialist_service',
    label: 'Socialist (NGOs / Service)',
    guj_label: 'સેવાકીય (NGO/ સેવા)',
  },
  {
    value: 'corporate_government_institute',
    label: 'Corporate / Government Institute',
    guj_label: 'કોર્પોરેટ / સરકારી સંસ્થાન',
  },
  { value: 'personal_service', label: 'Personal service', guj_label: 'વ્યક્તિગત સેવાકાર્ય' },
  {
    value: 'family_sphere_of_union_thought',
    label: 'The family sphere of union thought',
    guj_label: 'સંઘ વિચારના પરિવાર ક્ષેત્ર',
  },
  { value: 'special', label: 'Special', guj_label: 'વિશેષ' },
];

export const detail_of_various_service_work_options = [
  { value: 'health', label: 'Health', guj_label: 'સ્વાસ્થ્ય' },
  { value: 'education', label: 'Education', guj_label: 'શિક્ષણ' },
  { value: 'self_reliance', label: 'Self Reliance', guj_label: 'સ્વાવલંબન' },
  { value: 'women_development', label: 'Women Development', guj_label: 'નારીના વિકાસ માટે' },
  { value: 'special_activities', label: 'Special Activities', guj_label: 'વિશેષ કર્યો' },
];

export const scope_of_the_organization_options = [
  { value: 'all_india', label: 'All India', guj_label: 'સમગ્ર ભારતમાં' },
  { value: 'all_gujarat', label: 'All Gujarat', guj_label: 'સમગ્ર ગુજરાત' },
  {
    value: 'central_gujarat',
    label: 'Central Gujarat (Nadiad, Panchmahal, Karnavati-East, Karnavati-West and Surendranagar)',
    guj_label: 'મધ્ય ગુજરાત (નડિયાદ , પંચમહાલ , કર્ણાવતી-પૂર્વ , કર્ણાવતી-પશ્ચિમ અને સુરેન્દ્રનગર)',
  },
  {
    value: 'south_gujarat',
    label: 'South Gujarat (Navsari, Surat, Bharuch and Vadodara)',
    guj_label: 'દક્ષિણ ગુજરાત (નવસારી , સુરત , ભરુચ અને વડોદરા)',
  },
  {
    value: 'north_gujarat',
    label: 'North Gujarat (Gandhinagar, Banaskantha and Mehsana)',
    guj_label: 'ઉત્તર ગુજરાત (ગાંધીનગર , બનાસકાંઠા અને મહેસાણા)',
  },
  {
    value: 'saurashtra',
    label: 'Saurashtra (Rajkot, Bhavnagar, Junagadh, and Kutch)',
    guj_label: 'સૌરાષ્ટ્ર (રાજકોટ , ભાવનગર , જુનાગઢ અને કચ્છ)',
  },
];

export const jilla_places_options = [
  { value: 'ahmedabad', label: 'Ahmedabad', guj_label: 'અમદાવાદ' },
  { value: 'gandhinagar', label: 'Gandhinagar', guj_label: 'ગાંધીનગર' },
  { value: 'surendranagar', label: 'Surendranagar', guj_label: 'સુરેન્દ્રનગર' },
  { value: 'kheda', label: 'Kheda', guj_label: 'ખેડા' },
  { value: 'anand', label: 'Anand', guj_label: 'આનંદ' },
  { value: 'arvalli', label: 'Arvalli', guj_label: 'અરવલ્લી' },
  { value: 'panchmahal', label: 'Panchmahal', guj_label: 'પંચમહાલ' },
  { value: 'dahod', label: 'Dahod', guj_label: 'દાહોદ' },
  { value: 'mahisagar', label: 'Mahisagar', guj_label: 'મહિસાગર' },
  { value: 'vadodara', label: 'Vadodara', guj_label: 'વડોદરા' },
  { value: 'chota_udaypur', label: 'Chota Udaypur', guj_label: 'છોટાઉદેપુર' },
  { value: 'bharuch', label: 'Bharuch', guj_label: 'ભરૂચ' },
  { value: 'narmada', label: 'Narmada', guj_label: 'નર્મદા' },
  { value: 'surat', label: 'Surat', guj_label: 'સુરત' },
  { value: 'tapi', label: 'Tapi', guj_label: 'તાપી' },
  { value: 'navsari', label: 'Navsari', guj_label: 'નવસારી' },
  { value: 'dang', label: 'Dang', guj_label: 'ડાંગ' },
  { value: 'valsad', label: 'Valsad', guj_label: 'વલસાડ' },
  { value: 'mahesana', label: 'Mahesana', guj_label: 'મહેસાણા' },
  { value: 'patan', label: 'Patan', guj_label: 'પાટણ' },
  { value: 'banaskantha', label: 'Banaskantha', guj_label: 'બનાસકાંઠા' },
  { value: 'sabarkantha', label: 'Sabarkantha', guj_label: 'સાબરકાંઠા' },
  { value: 'gir_somnath', label: 'Gir Somnath', guj_label: 'ગીર સોમનાથ' },
  { value: 'junaghadh', label: 'Junaghadh', guj_label: 'જુનાગઢ' },
  { value: 'porbandar', label: 'Porbandar', guj_label: 'પોરબંદર' },
  { value: 'devbhumi_dhwarka', label: 'Devbhumi Dhwarka', guj_label: 'દેવભૂમી-દ્વારકા' },
  { value: 'amreli', label: 'Amreli', guj_label: 'અમરેલી' },
  { value: 'bhavnagar', label: 'Bhavnagar', guj_label: 'ભાવનગર' },
  { value: 'botad', label: 'Botad', guj_label: 'બોટાદ' },
  { value: 'rajkot', label: 'Rajkot', guj_label: 'રાજકોટ' },
  { value: 'jamnagar', label: 'Jamnagar', guj_label: 'જામનગર' },
  { value: 'morbi', label: 'Morbi', guj_label: 'મોરબી' },
  { value: 'katch', label: 'Katch', guj_label: 'કચ્છ' },
];

export const game_district_options = [
  { value: 'ahmedabadcity', label: 'Ahmedabad City', guj_label: 'અમદાવાદ શહર' },
  { value: 'ahmedabadrural', label: 'Ahmedabad Rural', guj_label: 'અમદાવાદ પ્રાન્ત' },
  { value: 'amreli', label: 'Amreli', guj_label: 'અમરેલી' },
  { value: 'anand', label: 'Anand', guj_label: 'આણંદ' },
  { value: 'aravali', label: 'Aravali', guj_label: 'અરવલ્લી' },
  { value: 'banaskantha', label: 'Banaskantha', guj_label: 'બનાસકાંઠા' },
  { value: 'bharuch', label: 'Bharuch', guj_label: 'ભરૂચ' },
  { value: 'bhavnagar', label: 'Bhavnagar', guj_label: 'ભાવનગર' },
  { value: 'bhavnagarcity', label: 'Bhavnagar City', guj_label: 'ભાવનગર શહર' },
  { value: 'botad', label: 'Botad', guj_label: 'બોટાદ' },
  { value: 'chhotaudepur', label: 'Chhota Udepur', guj_label: 'છોટાઉદેપુર' },
  { value: 'dahod', label: 'Dahod', guj_label: 'દાહોદ' },
  { value: 'dang', label: 'Dang', guj_label: 'ડાંગ' },
  { value: 'devbhoomidwarka', label: 'DevBhoomi Dwarka', guj_label: 'દેવભૂમિ દ્વારકા' },
  { value: 'gandhinagar', label: 'Gandhinagar', guj_label: 'ગાંધીનગર' },
  { value: 'girsomnath', label: 'Gir Somnath', guj_label: 'ગીર સોમનાથ' },
  { value: 'jamnagarrural', label: 'Jamnagar Rural', guj_label: 'જામનગર પ્રાન્ત' },
  { value: 'jamnagarcity', label: 'Jamnagar City', guj_label: 'જામનગર શહર' },
  { value: 'junagadh', label: 'Junagadh', guj_label: 'જુનાગઢ' },
  { value: 'kachchha', label: 'Kachchha', guj_label: 'કચ્છ' },
  { value: 'kheda', label: 'Kheda', guj_label: 'ખેડા' },
  { value: 'mahesana', label: 'Mahesana', guj_label: 'મહેસાણા' },
  { value: 'mahisagar', label: 'Mahi Sagar', guj_label: 'મહીસાગર' },
  { value: 'morbi', label: 'Morbi', guj_label: 'મોરબી' },
  { value: 'narmada', label: 'Narmada', guj_label: 'નર્મદા' },
  { value: 'navsari', label: 'Navsari', guj_label: 'નવસારી' },
  { value: 'panchmahal', label: 'Panchmahal', guj_label: 'પંચમહાલ' },
  { value: 'patan', label: 'Patan', guj_label: 'પાટણ' },
  { value: 'porbandar', label: 'Porbandar', guj_label: 'પોરબંદર' },
  { value: 'rajkotrural', label: 'Rajkot Rural', guj_label: 'રાજકોટ પ્રાન્ત' },
  { value: 'rajkotcity', label: 'Rajkot City', guj_label: 'રાજકોટ શહર' },
  { value: 'sabarkantha', label: 'Sabarkantha', guj_label: 'સાબરકાંઠા' },
  { value: 'surat', label: 'Surat', guj_label: 'સુરત' },
  { value: 'suratrural', label: 'Surat Rural', guj_label: 'સુરત પ્રાન્ત' },
  { value: 'surendranagar', label: 'Surendranagar', guj_label: 'સુરેન્દ્રનગર' },
  { value: 'tapi', label: 'Tapi', guj_label: 'તાપી' },
  { value: 'vadodaracity', label: 'Vadodara City', guj_label: 'વડોદરા શહર' },
  { value: 'vadodararural', label: 'Vadodara Rural', guj_label: 'વડોદરા પ્રાન્ત' },
  { value: 'valsad', label: 'Valsad', guj_label: 'વલસાડ' },
];
