import React from 'react';
import { Helmet } from 'react-helmet-async';
import GameRegistrationView from 'src/sections/HSSF/gameRegistration/game-registration-view';

const GameRegistrationMain = () => (
  <>
    <Helmet>
      <title> HSSF : GAME REGISTRATION</title>
    </Helmet>

    <GameRegistrationView />
  </>
);

export default GameRegistrationMain;
