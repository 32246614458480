import { palette } from '../palette';
import { customShadows } from '../custom-shadows';

// ----------------------------------------------------------------------

export function contrast(contrastBold, mode) {
  const theme = {
    ...(contrastBold &&
      mode === 'light' && {
        palette: {
          background: {
            default: palette(mode).grey[100],
          },
        },
      }),
  };

  const components = {
    ...(contrastBold && {
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: customShadows(mode).z4,
          },
        },
      },
    }),
  };

  return {
    theme,
    components,
  };
}

const data = [
  { key: 'email', value: 'bipin12q1@gmail.com', type: 'text' },
  { key: 'username', value: 'bipin12q1@gmail.com', type: 'text' },
  { key: 'password', value: 'User@123', type: 'text' },
  { key: 'firstName', value: 'bipin', type: 'text' },
  { key: 'lastName', value: 'bhalu', type: 'text' },
  { key: 'mobileNo', value: '95102702741', type: 'text' },
  { key: 'whatsappNo', value: '95102702741', type: 'text' },
  { key: 'profession', value: 'it engineer', type: 'text' },
  { key: 'address', value: 'test tatsafsd', type: 'text' },
  { key: 'pinCode', value: '365630', type: 'text' },
  { key: 'taluka', value: '2', type: 'text' },
  { key: 'jilla', value: '2', type: 'text' },
  { key: 'vibhag', value: '2', type: 'text' },
  { key: 'user_role', value: '6', type: 'text' },
  { key: 'talukaName', value: 'Navsari', type: 'text' },
  { key: 'jillaName', value: 'Navsari', type: 'text' },
  { key: 'user_role_name', value: 'karykarta', type: 'text', enabled: true },
];
